import { MaterialIcons } from '@expo/vector-icons';
import { Flex, Icon } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal as RNModal, ScrollView, TouchableOpacity, View } from 'react-native';

import { DEVICE_HEIGHT } from '../../constants';
import { Button } from '../Button/Button';
import { styles } from './styles';

export const Modal = ({
  isOpen,
  onClose,
  isScrollable = true,
  showCloseButton = false,
  showCloseIcon = true,
  iconColor = 'black',
  iconSize = 12,
  icons = [],
  children,
  maxWidth,
}) => {
  const content = isScrollable ? (
    <ScrollView
      style={{
        maxHeight: DEVICE_HEIGHT - 80,
      }}
    >
      {children}
    </ScrollView>
  ) : (
    children
  );

  return (
    <RNModal visible={isOpen} transparent={true}>
      <View style={styles.inner}>
        <View style={[styles.view, maxWidth ? { maxWidth } : {}]}>
          <View style={styles.icons}>
            {icons.map((icon) => (
              <TouchableOpacity key={icon.name} onPress={icon.onClick}>
                <MaterialIcons
                  name={icon.name}
                  size={iconSize}
                  iconColor={iconColor}
                  style={[styles.additionalIcon, { color: iconColor }]}
                />
              </TouchableOpacity>
            ))}
            {showCloseIcon && (
              <TouchableOpacity onPress={onClose}>
                <Icon name='close' as={MaterialIcons} />
              </TouchableOpacity>
            )}
          </View>
          {content}
          {showCloseButton && (
            <Flex justify='flex-end'>
              <Button intlId='app.close' onPress={onClose} variant='ghost' />
            </Flex>
          )}
        </View>
      </View>
    </RNModal>
  );
};

Modal.propTypes = {
  showCloseButton: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  iconSize: PropTypes.number,
  maxWidth: PropTypes.number,
  iconColor: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isScrollable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
