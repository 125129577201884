import { AddIcon, Button, Tooltip } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Linking } from 'react-native';

import { PrintIcon } from '../../../../components/Icons';
import { useIntl } from '../../../../hooks';
import { AddTTNModal } from './AddTTNModal/AddTTNModal';

export const CreateTTNButton = memo(({ isUkrposhta = false, legalEntityId }) => {
  const intl = useIntl();

  const { setValue, formState, getValues } = useFormContext();
  const { deliveryInformation } = getValues();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = useCallback((ttn) => {
    setIsModalOpen(false);

    if (ttn) {
      setValue('deliveryInformation.ttn', ttn);
    }
  }, []);

  const handleOpenPFD = useCallback(() => {
    Linking.openURL(
      `/${isUkrposhta ? 'ukrposhta' : 'novaposhta'}/sticker?barcode=${
        deliveryInformation?.ttn
      }&legalEntityId=${legalEntityId}`,
    );
  }, [getValues, deliveryInformation?.ttn]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <AddTTNModal isOpen={isModalOpen} onClose={handleCloseModal} />
      <Tooltip
        _text={{
          color: 'dark',
        }}
        background={'gray.default'}
        isDisabled={!formState.isDirty}
        label={intl.formatMessage({ id: 'app.saveToCreateTTN' })}
        openDelay={500}
      >
        <Button
          isDisabled={formState.isDirty}
          onPress={handleOpenModal}
          _disabled={{ bgColor: 'gray.main', opacity: 1 }}
          _hover={{ bgColor: 'primary.default' }}
          bgColor={'lightGreen'}
          w={'32px'}
          h={'32px'}
        >
          <AddIcon color={'white'} />
        </Button>
      </Tooltip>
      {!formState.isDirty && deliveryInformation?.ttn && (
        <Button
          ml={'8px'}
          onPress={handleOpenPFD}
          _hover={{ bgColor: 'transparent' }}
          bgColor={'transparent'}
          w={'32px'}
          h={'32px'}
        >
          <PrintIcon color={'white'} />
        </Button>
      )}
    </>
  );
});

CreateTTNButton.displayName = 'CreateTTNButton';

CreateTTNButton.propTypes = {
  isUkrposhta: PropTypes.boolean,
  legalEntityId: PropTypes.number.isRequired,
};
