import { MaterialIcons } from '@expo/vector-icons';
import _ from 'lodash';
import { Checkbox, FlatList, Flex, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Modal, TouchableOpacity } from 'react-native';

import { Typography } from '../../../../components';
import { BoxIcon } from '../../../../components/Icons';
import inputStyles from '../../../../components/UI/Input/styles';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import { getValueFromPath } from '../../../../utils';
import { ConditionNumber } from './';
import styles from './styles';

export const ItemConditionField = ({
  index,
  readOnly,
  condition,
  setShowLocation,
  showLocation,
  originalOrderItem,
}) => {
  const { formState, getValues, setValue } = useFormContext();
  const fieldName = `orderItems.${index}.availability.allowedConditions`;

  const errorMessageText = getValueFromPath(formState.errors, fieldName)?.message;

  const conditionsList = _.uniqBy(
    [
      ...(originalOrderItem?.product?.availabilities || []),
      ...(originalOrderItem?.reserved || []),
    ].map((item) => item.condition),
    'id',
  );

  const setDefaultConditionsAllowed = () => {
    const defaultConditions = conditionsList?.filter(({ isDefault }) => isDefault);
    setValue(fieldName, defaultConditions);
  };

  useEffect(() => {
    if (getValues(fieldName) === undefined) {
      setDefaultConditionsAllowed();
    }
  }, [originalOrderItem?.availability?.allowedConditions]);

  const [visible, setVisible] = useState(false);

  const DropdownButton = useRef();
  const [dropdownY, setDropdownY] = useState(null);
  const [dropdownX, setDropdownX] = useState(null);

  const toggleDropdown = () => (visible ? setVisible(false) : openDropdown());

  const openDropdown = () => {
    DropdownButton.current.measure((_fx, _fy, w, h, px, py) => {
      setDropdownY(py + h);
      setDropdownX(IS_DESKTOP_SCREEN ? px : px - w);
    });
    setVisible(true);
  };

  const renderDropdown = () => (
    <Modal visible={visible && dropdownY && dropdownX} transparent animationType='none'>
      <TouchableOpacity style={styles.overlay} onPress={() => setVisible(false)}>
        <View style={[styles.dropdown, { top: dropdownY, left: dropdownX }]}>
          <FlatList
            data={conditionsList}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </TouchableOpacity>
    </Modal>
  );

  const renderItem = ({ item }) => (
    <TouchableOpacity key={item.id} activeOpacity={1}>
      <Checkbox
        isChecked={!!getValues(fieldName)?.find(({ id }) => +id === +item.id)}
        onChange={(isTrue) => handleChangeCheckbox(isTrue, item)}
      >
        <Flex>
          <ConditionNumber condition={item} style={styles.conditionDropdownNumber} />
          <Typography style={styles.conditionDropdownName}>{item.name}</Typography>
        </Flex>
      </Checkbox>
    </TouchableOpacity>
  );

  const renderConditionsInput = () => {
    const conditionsToRender = getValues(fieldName)?.filter((item) =>
      conditionsList.find(({ id }) => id === item.id),
    );

    return (
      <Flex
        style={[
          inputStyles.input,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            overflow: 'hidden',
            paddingBottom: !conditionsToRender?.length ? 20 : 8,
          },
        ]}
        onClick={toggleDropdown}
        ref={DropdownButton}
      >
        {conditionsToRender?.map((item) => (
          <ConditionNumber key={item.id} condition={item} />
        ))}
        <MaterialIcons
          name={visible ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
          size={17}
          color='black'
          onPress={toggleDropdown}
          style={styles.arrow}
        />
      </Flex>
    );
  };

  const handleChangeCheckbox = (isTrue, item) => {
    if (isTrue) return setValue(fieldName, [...(getValues(fieldName) || []), item]);
    const removedValues = getValues(fieldName)?.filter(({ id }) => +id !== +item.id);
    setValue(fieldName, removedValues);
  };

  if (!conditionsList?.length) {
    return <Typography style={styles.notAvailable} intlId={'app.notAvailable'} />;
  }

  if (readOnly) {
    return (
      <View
        style={[
          styles.itemConditionField,
          showLocation && styles.itemConditionFieldActive,
        ]}
      >
        <Flex
          style={
            // eslint-disable-next-line react-native/no-inline-styles
            {
              overflow: 'hidden',
            }
          }
          onClick={() => {
            setShowLocation(!showLocation);
          }}
          ref={DropdownButton}
        >
          <ConditionNumber
            condition={condition}
            style={styles.conditionNumber}
            fontSize={11}
          />
          <BoxIcon />
        </Flex>
      </View>
    );
  }

  return (
    <>
      {renderConditionsInput()}
      {errorMessageText && <>{errorMessageText}</>}
      {visible && renderDropdown()}
    </>
  );
};

ItemConditionField.propTypes = {
  index: PropTypes.number,
  status: PropTypes.string,
  statusWasChanged: PropTypes.string,
  readOnly: PropTypes.bool,
  condition: PropTypes.object,
  setShowLocation: PropTypes.func,
  showLocation: PropTypes.bool,
  originalOrderItem: PropTypes.object,
};
