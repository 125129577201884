/* eslint-disable react-native/no-inline-styles */
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { View } from 'react-native';
import { Title } from 'react-native-paper';

import { TextFieldHookFrom } from '../../../../components';
import { QuerySelectWithSearchHookForm } from '../../../../components/QuerySelectWithSearch/QuerySelectWithSearchHookForm';
import { SelectHookForm } from '../../../../components/Select/SelectHookForm';
import { CANCEL_STATUS_ID, IS_DESKTOP_SCREEN } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import { GET_ORDER_CANCEL_REASONS } from '../../../../graphql';
import { useIntl, useOrderCancelReasons } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';

const OrderStatusContainer = (props) => {
  const { control, watch } = useFormContext();

  const defaultStatus = watch('defaultStatus');
  const isReservationCanceled = watch('isReservationCanceled');

  const { reservationType } =
    props.statuses.find(({ id }) => +id === +defaultStatus) || {};

  return (
    <OrderStatus
      defaultStatus={defaultStatus}
      reservationType={reservationType}
      isReservationCanceled={isReservationCanceled}
      {...props}
      control={control}
    />
  );
};

const OrderStatus = memo(
  ({
    statuses,
    users,
    sources,
    control,
    reservationType,
    defaultStatus,
    isReservationCanceled,
  }) => {
    const intl = useIntl();

    const { watch } = useFormContext();
    const [initReason, setInitReason] = useState(null);

    const status = Number(watch('status'));
    const cancelReasonId = Number(watch('cancelReasonId'));
    const [getInitReason] = useOrderCancelReasons();

    useEffect(() => {
      if (!cancelReasonId) return;

      getInitReason({
        variables: { ids: [Number(cancelReasonId)] },
        onCompleted: (data) => {
          setInitReason(data.orderCancelReasons[0]);
        },
      });
    }, [cancelReasonId]);

    const filteredStatuses = useMemo(() => {
      const allowedReservationTypes = [];
      switch (isReservationCanceled ? 'none' : reservationType) {
        case 'reserved':
          allowedReservationTypes.push('reserved', 'sent');
          break;
        case 'sent':
          allowedReservationTypes.push('sent');
          break;
        case 'none':
        default:
          allowedReservationTypes.push('none', 'reserved');
      }

      return statuses.filter((status) =>
        allowedReservationTypes.includes(status.reservationType),
      );
    }, [defaultStatus, isReservationCanceled, statuses]);

    return (
      <>
        <Title style={stylesGlobal.title}>
          {intl.formatMessage({ id: 'order.status' })}
        </Title>
        <View style={stylesGlobal.formWrapper}>
          <View style={{ width: IS_DESKTOP_SCREEN ? '30%' : '100%' }}>
            <SelectHookForm
              name='status'
              label='order.status'
              options={transformSelectOptions({
                data: filteredStatuses,
                label: 'name',
              })}
              control={control}
            />
          </View>
          <View style={{ width: IS_DESKTOP_SCREEN ? '30%' : '100%' }}>
            <SelectHookForm
              name='user'
              label='app.manager'
              options={transformSelectOptions({
                data: users,
                label: ['firstName', 'lastName'],
              })}
              control={control}
            />
          </View>
          <View style={{ width: IS_DESKTOP_SCREEN ? '30%' : '100%' }}>
            <SelectHookForm
              name='source'
              label='app.source'
              options={transformSelectOptions({
                data: sources,
                label: 'name',
              })}
              control={control}
            />
          </View>
        </View>
        {status === CANCEL_STATUS_ID ? (
          <View style={stylesGlobal.formWrapper}>
            <View style={{ width: IS_DESKTOP_SCREEN ? '30%' : '100%' }}>
              <QuerySelectWithSearchHookForm
                width={'100%'}
                label='order.cancelReason'
                valueProperty={'id'}
                labelProperty={'name'}
                placeholderIntlId='app.enterValue'
                initialOption={
                  initReason ? { value: initReason.id, label: initReason.name } : null
                }
                fieldName='cancelReasonId'
                graphqlName={'orderCancelReasons'}
                graphql={GET_ORDER_CANCEL_REASONS}
                mb={0}
                pr={0}
                pl={0}
                isValChange
              />
            </View>
            <View style={{ width: IS_DESKTOP_SCREEN ? '65%' : '100%' }}>
              <TextFieldHookFrom
                width={'100%'}
                name='cancelReasonComment'
                label='order.cancelReasonComment'
              />
            </View>
          </View>
        ) : null}
      </>
    );
  },
);

OrderStatus.displayName = 'OrderStatus';

OrderStatus.propTypes = {
  statuses: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired,
  defaultStatus: PropTypes.string,
  control: PropTypes.object,
  reservationType: PropTypes.string,
  isReservationCanceled: PropTypes.bool,
};

OrderStatusContainer.propTypes = {
  statuses: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired,
};

export default OrderStatusContainer;
