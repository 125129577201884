import { Box, Flex, Radio, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Checkbox, FormRow, TextFieldHookFrom } from '../../../../../components';
import { useParams } from '../../../../../hooks';
import { CreateTTNButton } from '../CreateTTNButton';
import styles from '../styles';
import { UkrPoshtaCityAutoCompleteFieldHookForm } from './UkrPoshtaCityAutoCompleteFieldHookForm';
import { UkrPoshtaWarehouseAutoCompleteFieldHookForm } from './UkrPoshtaWarehouseAutoCompleteFieldHookForm';

export const UkrPoshtaFieldsHookForm = ({ legalEntityId }) => {
  const { id } = useParams();

  const intl = useIntl();

  const { setValue } = useFormContext();

  const ukrposhtaShipping = useWatch({ name: 'ukrposhtaShipping' });

  const handleChecboxChanged = () => {
    setValue('ukrposhtaShipping.paidByRecipient', !ukrposhtaShipping.paidByRecipient);
  };

  const handleCityChanged = useCallback((val) => {
    setValue('ukrposhtaShipping.cityFullName', val?.label || '');
    setValue('ukrposhtaShipping.cityName', val?.info || '');
  }, []);

  const handleWarehouseChanged = useCallback((val) => {
    setValue('ukrposhtaShipping.warehouseName', val?.label || '');
    setValue('ukrposhtaShipping.postcode', val?.info || '');
  }, []);

  return (
    <>
      <FormRow>
        <FormRow.Left>
          <UkrPoshtaCityAutoCompleteFieldHookForm
            onChange={handleCityChanged}
            name='ukrposhtaShipping.cityId'
            cityId={ukrposhtaShipping?.cityId}
            cityName={ukrposhtaShipping?.cityName}
            cityFullName={ukrposhtaShipping?.cityFullName}
          />
        </FormRow.Left>
        <FormRow.Right>
          <Flex alignItems={'center'}>
            <Box flexGrow={1} mr={'14px'}>
              <TextFieldHookFrom
                width={'100%'}
                name='deliveryInformation.ttn'
                label='app.ttn'
                keyboardType='numeric'
              />
            </Box>
            {id && <CreateTTNButton isUkrposhta legalEntityId={legalEntityId} />}
          </Flex>
        </FormRow.Right>
      </FormRow>
      <FormRow>
        <FormRow.Left>
          <Radio.Group
            defaultValue={ukrposhtaShipping?.deliveryType}
            name='ukrposhtaShipping.deliveryType'
            label={intl.formatMessage({ id: 'app.serviceType' })}
            onChange={(value) => {
              setValue('ukrposhtaShipping.deliveryType', value);
            }}
          >
            <View style={styles.deliveryType}>
              <Radio value='W2W'>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({ id: 'app.warehouse' })}
                </Text>
              </Radio>
              <Radio value='W2D' style={styles.serviceTypeRadio}>
                <Text style={styles.radioLabel}>
                  {intl.formatMessage({ id: 'app.courier' })}
                </Text>
              </Radio>
            </View>
          </Radio.Group>
          <Box mb={'16px'} mt={'16px'}>
            <Checkbox
              checked={ukrposhtaShipping.paidByRecipient}
              onChange={handleChecboxChanged}
              label={intl.formatMessage({ id: 'app.paidByRecipient' })}
            />
          </Box>
        </FormRow.Left>
        <FormRow.Right>
          {ukrposhtaShipping?.deliveryType === 'W2W' && ukrposhtaShipping?.cityId && (
            <UkrPoshtaWarehouseAutoCompleteFieldHookForm
              cityId={ukrposhtaShipping?.cityId}
              warehouseId={ukrposhtaShipping?.warehouseId}
              warehouseName={ukrposhtaShipping?.warehouseName}
              name='ukrposhtaShipping.warehouseId'
              onChange={handleWarehouseChanged}
            />
          )}
          <TextFieldHookFrom
            width={'100%'}
            name='ukrposhtaShipping.postcode'
            label='app.postcode'
          />
        </FormRow.Right>
      </FormRow>
      {ukrposhtaShipping?.deliveryType === 'W2D' && ukrposhtaShipping?.cityId && (
        <FormRow>
          <FormRow.Left>
            <TextFieldHookFrom
              width={'100%'}
              name='ukrposhtaShipping.street'
              label='app.street'
            />
          </FormRow.Left>
          <FormRow.Right>
            <Flex justifyContent='space-between'>
              <View width='40%'>
                <TextFieldHookFrom
                  width={'100%'}
                  name='ukrposhtaShipping.houseNumber'
                  label='app.building'
                />
              </View>
              <View width='50%'>
                <TextFieldHookFrom
                  width={'100%'}
                  name='ukrposhtaShipping.apartmentNumber'
                  label='app.flat'
                />
              </View>
            </Flex>
          </FormRow.Right>
        </FormRow>
      )}
    </>
  );
};

UkrPoshtaFieldsHookForm.propTypes = {
  legalEntityId: PropTypes.number.isRequired,
};
