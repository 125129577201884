import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Text } from 'react-native';

import { Modal } from '../../../../components';
import { useNotificationsContext } from '../../../../context';
import { colors } from '../../../../theme';
import countTotalPriceByOrderItems from '../../../../utils/countTotalPriceByOrderItems';

const BankAccountDetailsModal = ({ legalEntity, orderId, onClose }) => {
  const intl = useIntl();
  const { watch } = useFormContext();
  const orderItems = watch('orderItems');
  const totalPrice = countTotalPriceByOrderItems(orderItems);
  const { showNotification } = useNotificationsContext();

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `Одержувач: ${legalEntity.title}\n` +
          `ЄДРПОУ: ${legalEntity.tin}\n` +
          `IBAN: ${iban}\n` +
          `Сума: ${totalPrice} ${intl.formatMessage({ id: 'currency.uah' })}\n` +
          `Призначення: оплата за товар, замовлення №${orderId}`,
      );

      showNotification({
        title: 'order.bankAccountDetailsCopyNotificationTitle',
        message: 'order.bankAccountDetailsCopyNotificationSuccess',
      });
    } catch (error) {
      showNotification({
        title: 'order.bankAccountDetailsCopyNotificationTitle',
        message: 'order.bankAccountDetailsCopyNotificationFailed',
        type: 'error',
      });
    }
  };

  const iban = _.find(legalEntity.bankAccounts, 'iban')?.iban;

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      showCloseButton={true}
      showCloseIcon={false}
      iconSize={24}
      iconColor={colors.primary.default}
      maxWidth={450}
      icons={[
        {
          name: 'content-copy',
          onClick: copyToClipboard,
        },
      ]}
    >
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
        <li>
          <Text>Одержувач: </Text>
          <b>{legalEntity.title}</b>
        </li>
        <li>
          <Text>ЄДРПОУ: </Text>
          <b>{legalEntity.tin}</b>
        </li>
        <li>
          <Text>IBAN: </Text>
          <b>{iban}</b>
        </li>
        <li>
          <Text>Сума: </Text>
          <b>{totalPrice}</b>
          <Text> {intl.formatMessage({ id: 'currency.uah' })}</Text>
        </li>
        <li>
          <Text>Призначення: оплата за товар, замовлення №</Text>
          <b>{orderId}</b>
        </li>
      </ul>
    </Modal>
  );
};

BankAccountDetailsModal.displayName = 'BankAccountDetailsModal';

BankAccountDetailsModal.propTypes = {
  legalEntity: PropTypes.object,
  orderId: PropTypes.number,
  onClose: PropTypes.func,
};

export default BankAccountDetailsModal;
