/* eslint-disable react-native/no-inline-styles */
import { MaterialIcons } from '@expo/vector-icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { View } from 'react-native';
import { Title } from 'react-native-paper';

import { SelectHookForm } from '../../../../components/Select/SelectHookForm';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import { useIntl } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';
import BankAccountDetailsModal from './BankAccountDetailsModal';
import styles from './styles';

const PaymentProps = memo(({ paymentTypes, paymentStatuses, legalEntity }) => {
  const intl = useIntl();
  const { watch } = useFormContext();
  const [showBankAccountDetailsModal, setShowBankAccountDetailsModal] = useState(false);
  const paymentTypeId = watch('paymentType');
  const paymentStatusId = watch('paymentStatus');
  const orderId = watch('id');
  const showBankDetailsButton =
    orderId &&
    legalEntity?.tin &&
    paymentStatusId === '1' &&
    _.find(paymentTypes, { id: paymentTypeId })?.type === 'prepayment';

  return (
    <>
      <Title style={stylesGlobal.title}>
        {intl.formatMessage({ id: 'app.payment' })}
        {showBankDetailsButton && (
          <MaterialIcons
            name='account-balance'
            size={24}
            onClick={() => setShowBankAccountDetailsModal(true)}
            style={styles.additionalIcon}
          />
        )}
      </Title>
      <View style={stylesGlobal.formWrapper}>
        <View style={IS_DESKTOP_SCREEN && { width: '30%' }}>
          <SelectHookForm
            name='paymentStatus'
            label={'app.paymentStatus'}
            options={transformSelectOptions({
              data: paymentStatuses,
              label: 'name',
            })}
          />
        </View>
        <View style={IS_DESKTOP_SCREEN && { width: '65%' }}>
          <SelectHookForm
            name='paymentType'
            label={'app.paymentOption'}
            options={transformSelectOptions({
              data: paymentTypes,
              label: 'name',
            })}
          />
        </View>
      </View>
      {showBankAccountDetailsModal && (
        <BankAccountDetailsModal
          legalEntity={legalEntity}
          onClose={() => setShowBankAccountDetailsModal(false)}
          orderId={orderId}
        />
      )}
    </>
  );
});

PaymentProps.displayName = 'PaymentProps';

PaymentProps.propTypes = {
  paymentTypes: PropTypes.array.isRequired,
  paymentStatuses: PropTypes.array.isRequired,
  legalEntity: PropTypes.object,
};

export default PaymentProps;
