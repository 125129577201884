import { Spinner, View } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';

import { useFetchMore, useFilter } from '../../hooks';
import { SortIcon } from '../Icons';
import { Typography } from '../Typography/Typography';
import EditButton from './EditButton';
import OnChangeButton from './OnChangeButton';
import { sortIconStyles, TableStyles } from './tableStyles';

export const DesktopTable = ({
  editable = true,
  fetchMoreData,
  children,
  inModal = false,
}) => {
  const [node, isLoading] = useFetchMore(fetchMoreData);

  return (
    <>
      <table className={`desktop-table ${editable ? 'editable' : ''}`}>{children}</table>
      {node ? <span ref={node} /> : null}
      {isLoading ? (
        <View className='desktop-table-spinner'>
          <Spinner />
        </View>
      ) : null}
      <TableStyles inModal={inModal} />
    </>
  );
};

DesktopTable.Row = function Row({
  onEditButtonClick,
  children,
  onChange,
  iconName = 'edit',
  iconColor = 'white',
}) {
  const hasEditButton = typeof onEditButtonClick === 'function';
  const hasOnChangeButton = typeof onChange === 'function';
  return (
    <>
      <tr>
        {children}
        {hasEditButton && (
          <td>
            <EditButton onClick={onEditButtonClick} />
          </td>
        )}
        {hasOnChangeButton && (
          <td>
            <OnChangeButton
              onClick={onChange}
              iconName={iconName}
              iconColor={iconColor}
            />
          </td>
        )}
      </tr>
    </>
  );
};

DesktopTable.Title = function Title({
  sortId,
  intlId,
  color = 'dark',
  width = 'auto',
  children,
  textAlign,
  style = {},
}) {
  if (sortId)
    return (
      <TitleWithFilter
        sortId={sortId}
        intlId={intlId}
        color={color}
        style={{ width, textAlign }}
      >
        {children}
      </TitleWithFilter>
    );

  return (
    <th style={{ width, textAlign }}>
      <Typography intlId={intlId} color={color} style={style}>
        {children}
      </Typography>
    </th>
  );
};

const TitleWithFilter = ({ sortId, intlId, color, children, style }) => {
  const { filters, setFilters } = useFilter();

  const getDirection = () => {
    if (filters?.field === sortId) {
      return filters?.direction === 'desc' ? 'asc' : 'desc';
    }
    return 'desc';
  };

  const handleThClick = () => {
    if (!sortId) return null;
    setFilters({ field: sortId, direction: getDirection() });
  };

  return (
    <th style={style}>
      <button type='button' onClick={handleThClick}>
        <Typography intlId={intlId} color={color}>
          {children}
        </Typography>
        <SortIcon style={sortIconStyles} />
      </button>
    </th>
  );
};

DesktopTable.Cell = function Cell({
  color = 'text.default',
  style = {},
  children,
  maxWidth = 'auto',
  width = 'auto',
  height = 'auto',
  textAlign,
  verticalAlign,
  colSpan,
  padding,
  border,
  isQuantity = false,
}) {
  const childrenTypeof = typeof children;
  return (
    <td
      colSpan={colSpan}
      style={{ maxWidth, width, height, textAlign, verticalAlign, padding, border }}
    >
      {!children && children !== 0 && (
        <Typography intlId={isQuantity ? 'app.notAvailable' : 'app.noData'} />
      )}
      {childrenTypeof === 'object' && children}
      {(children || children === 0) && childrenTypeof !== 'object' && (
        <Typography color={color} style={style}>
          {children}
        </Typography>
      )}
    </td>
  );
};

DesktopTable.Body = function Body({ children }) {
  const isEmpty = !children || children?.length === 0;

  return (
    <tbody className={isEmpty ? 'is-empty' : null}>
      {isEmpty ? (
        <tr>
          <td>
            <Typography intlId='app.noData' />
          </td>
        </tr>
      ) : (
        children
      )}
    </tbody>
  );
};

DesktopTable.Head = function Head({ children }) {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};

DesktopTable.propTypes = {
  editable: PropTypes.bool,
  fetchMoreData: PropTypes.func,
  children: PropTypes.node.isRequired,
  inModal: PropTypes.bool,
};
DesktopTable.Head.propTypes = {
  children: PropTypes.node.isRequired,
};
DesktopTable.Row.propTypes = {
  onEditButtonClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
};
DesktopTable.Title.propTypes = {
  intlId: PropTypes.string,
  sortId: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};
TitleWithFilter.propTypes = {
  intlId: PropTypes.string,
  sortId: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape({
    textAlign: PropTypes.string,
  }),
};
DesktopTable.Body.propTypes = {
  children: PropTypes.node,
};
DesktopTable.Cell.propTypes = {
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.string,
  verticalAlign: PropTypes.string,
  colSpan: PropTypes.number,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  border: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isQuantity: PropTypes.bool,
};
