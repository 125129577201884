/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { Title } from 'react-native-paper';

import { FormRow, TextFieldHookFrom, Typography } from '../../../../components';
import { DatePickerHookForm } from '../../../../components/DatePicker/DatePickerHookForm';
import { SelectHookForm } from '../../../../components/Select/SelectHookForm';
import globalStyles from '../../../../globalStyles';
import { useIntl } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';
import { NovaPoshtaFieldsHookForm } from './NovaPoshtaFieldsHookForm';
import styles from './styles';
import { UkrPoshtaFieldsHookForm } from './UkrPoshta/UkrPoshtaFieldsHookForm';

const DeliveryPropsContainer = (props) => {
  const selectedShippingType = useWatch({ name: 'shippingType' });
  const deliveryInformation = useWatch({ name: 'deliveryInformation' });

  return (
    <DeliveryProps
      {...props}
      selectedShippingType={selectedShippingType}
      address={deliveryInformation.address}
    />
  );
};

const DeliveryProps = memo(
  ({ shippingTypes, selectedShippingType, address, legalEntityId }) => {
    const intl = useIntl();

    return (
      <>
        <Title style={globalStyles.title}>
          {intl.formatMessage({ id: 'app.delivery' })}
        </Title>
        <FormRow style={globalStyles.formWrapper}>
          <FormRow.Left>
            <SelectHookForm
              name='shippingType'
              label={'app.deliveryOption'}
              options={transformSelectOptions({
                data: shippingTypes,
                label: 'name',
              })}
            />
          </FormRow.Left>
          <FormRow.Right>
            <DatePickerHookForm
              name='deliveryInformation.departureDate'
              label='app.departureDate'
              style={styles.departureDate}
              // eslint-disable-next-line react-native/no-inline-styles
              inputStyles={{
                fontSize: 14,
                height: 20,
                paddingBottom: 8,
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 2,
              }}
            />
          </FormRow.Right>
        </FormRow>
        {selectedShippingType &&
          (selectedShippingType === '1' ? (
            <NovaPoshtaFieldsHookForm legalEntityId={legalEntityId} />
          ) : selectedShippingType === '2' ? (
            <UkrPoshtaFieldsHookForm legalEntityId={legalEntityId} />
          ) : (
            <Typography intlId='app.noData' />
          ))}
        {address ? (
          <TextFieldHookFrom
            width={'100%'}
            name='deliveryInformation.address'
            label='app.address'
            isReadOnly
          />
        ) : null}
        <TextFieldHookFrom
          width={'100%'}
          name='deliveryInformation.comment'
          label='order.deliveryComment'
        />
      </>
    );
  },
);

DeliveryProps.displayName = 'DeliveryProps';

DeliveryProps.propTypes = {
  shippingTypes: PropTypes.array.isRequired,
  selectedShippingType: PropTypes.string,
  address: PropTypes.string,
  legalEntityId: PropTypes.number.isRequired,
};

DeliveryPropsContainer.propTypes = {
  shippingTypes: PropTypes.array.isRequired,
  legalEntityId: PropTypes.number.isRequired,
};

export default DeliveryPropsContainer;
